import React, { FC } from "react"
import { useUser } from "../../components/auth/FirebaseContext"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Json } from "../../components/Json"
import { noSsr } from "../../components/NoSsrContext"

const PageInner: FC = noSsr(() => {
  const { user } = useUser()

  return (
    <div>
      <Json>{user && user.email}</Json>
    </div>
  )
})

export default function Page() {
  return (
    <DebugLayout title="Firebase Event Listeners">
      <PageInner />
    </DebugLayout>
  )
}
